<template>
  <div>
    <app-header title="Dashboard" />

    <div v-if="loaded" class="container">
      <div class="row">
        <div class="col-12 col-sm-6 col-xl-3 mb-4">
          <div class="card card-widget">
            <div class="card-body">
              <h2 class="widget-title">System Overview</h2>
              <div class="row">
                <div class="col-6">
                  <p class="text-muted small">Total Screens</p>
                  <p class="lead">{{ data.screens_count }}</p>
                </div>
                <div class="col-6">
                  <p class="text-muted small">Active Faults</p>
                  <p class="lead">{{ data.screen_faults_count }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card card-xs-full">
        <div class="card-body pb-0">
          <div class="row">
            <div class="col"><h2>Active Faults</h2></div>
            <div class="col-auto">
              <router-link tag="a" :to="{ name: 'screens', query: { status: '2' } }" class="btn btn-primary"> View All </router-link>
            </div>
          </div>
        </div>
        <ul class="list-data list-data-screens">
          <li class="data-heading d-none d-xl-block">
            <div class="row">
              <div class="col-xl-3">Screen Name</div>
              <div class="col-xl-3 flex-grow-1">Tags</div>
              <div class="col-xl-2">Media Owner</div>
              <div class="col-xl-2">Current Brightness</div>
              <div class="col-xl-2">Ambient Brightness</div>
            </div>
          </li>
          <li v-for="screen in data.recent_active_faults" :key="screen.id">
            <screens-list-item :screen="screen" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from '@/utils'
import ScreensListItem from './media-owners/MediaOwnersScreensListItem'

export default {
  components: {
    ScreensListItem
  },

  data () {
    return {
      loaded: false,
      data: null,
    }
  },

  async created () {
    this.loaded = false
    const response = await http.get('dashboard/user')
    this.data = response.data.data
    this.loaded = true
  },
}
</script>
